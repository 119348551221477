@import "../assets/fonts/fonts.css";

.ProfileHomeContainer {
  font-family: 'SawarabiGothic', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 100;

    width: 100%;
    background-color: #F0F1FF;
    height: auto;
}

.ProfileHomeMain {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  /*margin-left: 15rem;*/
  align-items: center;
}

.NextSteps {
  background-color: #F2E2F3; /* Background color */
  color: #333; /* Text color */
  /*padding: 10px; *//* Padding around the text */
  /*border: 1px solid #ccc; *//* Border for the message box */
  border-radius: 5px; /* Rounded corners for a box-like appearance */
  margin: 10px 0; /* Margin to separate it from other content */
  font-size: 20px; /* Font size for the message text */
  text-align: center;
}

.NextSteps:empty {
  display: none;
}

.ProfileHomeTopbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Center horizontally */
  align-items: center;
}

.ProfileImage img {
  margin: 2rem;
  width: 15rem;
  height: 15rem; /* Make sure the width and height are equal */
  border-radius: 50%;
  box-shadow: 5px 5px 10px black;
  transform: perspective(500px) rotateX(2deg) rotateY(-1deg); /* Add this line */
}

.ProfileIntroduction {
  text-align: left; /* Left-justify the text within the section */
  max-width: 80%; /* Set a maximum width for the section, adjust as needed */
  min-width: 300px; /* Set a minimum width for the section */
  padding: 20px;
  border: 1px solid #ddd; /* Optional border for the section */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional box shadow for the section */
  margin: 0 auto; /* Center the section horizontally within the container */
}

.DependentList {
  display: flex;
  flex-direction: row;
}

.DependentListItem {
  align-items: center;
  margin-left: 4rem;
  width: 5rem;
}

.DependentPhoto img {
  width: 5rem;
  height: auto;
}

.DependentName {
  width: 5rem;
  text-align: center;
}

.ProfileHomeBottom {
  width: 100%;
  /* padding-bottom: 1000px; */
}

.UpdateProfileButton button {
  padding: 10px;
  background-color: #94E5FF80;
  border-radius: 5px;
  width: 10rem;
  border: none;
  cursor: pointer;
  font-family: 'SawarabiGothic', Helvetica, sans-serif;
  font-size: 1.1rem;
}

@media screen and (max-width: 900px) {
    .ProfileHomeMain {
        margin-top: 0.1rem;
        margin-left: 0.1rem;
    }
    
    .ProfileHomeTopbar {
      flex-direction: column;
    }
    
    .DependentList {
    flex-direction: column;
    }

    .DependentListItem {
      margin-left: 1rem;
    }
}