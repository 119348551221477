@import "../../assets/fonts/fonts.css";

.PublicProfileContainer {
  width: 100%;
  background-color: #F0F1FF;
  height: 100%;
  font-family: 'SawarabiGothic', Helvetica, sans-serif;
}

.PublicProfileMain {
  display: flex;
  flex-direction: column;
  width: 100%;
  /*margin-left: 15rem;*/
  justify-content: center; /* Center horizontally */
  align-items: center;
  gap: .5rem;
}

.PublicProfileLeftSidebar {
  width: 10rem;
  height: 100%;
  /*margin-left: 15rem;*/
}

.PublicProfileMain button {
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  background-color: #94E5FF80;
  width: 20rem;
  border: none;
  cursor: pointer;
  font-family: 'SawarabiGothic', Helvetica, sans-serif;
  font-size: 1.1rem;
}

.PublicHomeTopbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Center horizontally */
  align-items: center;
  /*width: 100%;*/
}

.PublicSunImage img {
  margin-top: 0.1rem;
  width: 10rem;
  height: auto;
}

.PublicHomeImage img {
  /*margin: 2rem;*/
  width: 14rem;
  height: auto;
}

.PublicHomeName {
  position: relative;
  margin: 5rem 5rem;
  
  background-color: #F0F1FF;
}

.PublicHomeName::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 70%;  /*Hack! Have to set it to 70% for the buttons to be visible and clickable. */
  background-image: url('../../assets/images/sun_smilling_figma.png');
  background-repeat: no-repeat;
  background-size: cover; /* or any other desired size */
  background-position: center; /* or any other desired position */
  opacity: 0.3; /* Adjust this value to control the background image's transparency (0.0 to 1.0) */
  z-index: 0;
}

.PublicHomeName p {
  font-size: 18px;
  line-height: .8;
}

.PublicHomeButtons {
  display: flex;
  flex-direction:column;
  gap:.2rem;
  z-index: 1;
}

.PublicHomeButtons button {
  z-index: 1;
}

.PublicEmergencyContact {
    background-color: #FAAABD80;
    width: 20rem;
    padding-left: .3rem;
    padding-right: .3rem;
    border-radius: 5px;
}

@media screen and (max-width: 768px) {
    .Main {
        margin-top: 0.1rem;
        margin-left: 0.1rem;
    }

    .PublicHomeTopbar {
      flex-direction: column;
    }

    .PublicSunImage img {
      display: none;
    }
    
    .PublicHomeImage img {
      width:70%;
      height: auto;
    }
    
    .PublicHomeName {
      margin: 1rem 1rem; 
    }

}
