@import "../../assets/fonts/fonts.css";

.TraitCategoriesContainer {
  width: 100%;
  background-color: #F0F1FF;
  height: 100%;
  font-family: 'SawarabiGothic', Helvetica, sans-serif;
}

.TraitCategoriesMain {
  width: 100%;
  /*margin-left: 15rem;*/
  align-items: center;
}

.TraitCategoriesTopbar {
  display: flex;
  align-items: center;
}

.TraitCategoriesName {
  /*margin: 5rem 5rem; */
  width: 100%;
  text-align: center;
}

.TraitCategoriesList {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.TraitCategory button {
  /*margin: 5rem 5rem; */
  /*text-align: center;*/
  padding: 10px;
  background-color: #94E5FF80;
  /*color: white;*/
  width: 20rem;
  border: none;
  cursor: pointer;
  font-family: 'SawarabiGothic', Helvetica, sans-serif;
  font-size: 1.1rem;
}

.CategoryIndexImage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: .3rem;
}

.CategoryIndexImage img {
  width: 10rem;
  height: 8rem; /* Make sure the width and height are equal */
}

.TraitCategoryBack {
  display: flex;
  width: 100%;
  flex-direction: column;
    align-items: center;

}
.TraitCategoryBack button {
  /*margin: 5rem 5rem; */
  /*text-align: center;*/
  margin-top: .4rem;

  padding: 10px;
  background-color: #94E5FF80;
  /*color: white;*/
  width: 10rem;
  border: none;
  cursor: pointer;
  font-family: 'SawarabiGothic', Helvetica, sans-serif;
  font-size: 1.1rem;
}

@media screen and (max-width: 900px) {
    .Main {
        margin-top: 0.1rem;
        margin-left: 0.1rem;
    }
}
