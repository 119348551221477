@import "../../assets/fonts/fonts.css";

.TraitDetailsContainer {
  width: 100%;
  background-color: #F0F1FF;
  height: 100%;
  font-family: 'SawarabiGothic', Helvetica, sans-serif;
}

.TraitDetailsMain {    
  width: 100%;
  /*margin-left: 15rem;*/
  align-items: center;
}

.TraitQuestionsList {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  text-align: flex-start;
  border: none;
  font-family: 'SawarabiGothic', Helvetica, sans-serif;
  font-size: 1.1rem;
}

.GoBackButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2px;

}
.GoBackButton button {
  padding: 10px;
  background-color: #94E5FF80;

  border-radius: 5px;
  width: 10rem;
  border: none;
  cursor: pointer;
  font-family: 'SawarabiGothic', Helvetica, sans-serif;
  font-size: 1.1rem;
}

.TraitQuestionDetails button:active {
  background-color: white;
}

.TraitQuestionDetails button:disabled {
  background-color: #BEBEBE;
}

.TraitQuestionDetails button {
  padding: 10px;
  background-color: #94E5FF80;
  border-radius: 5px;
  width: 20rem;
  border: none;
  cursor: pointer;
  font-family: 'SawarabiGothic', Helvetica, sans-serif;
  font-size: 1.1rem;
}

.TraitQuestionDetails button:active {
  background-color: white;
}

.TraitQuestionDetails button:disabled {
  background-color: #BEBEBE;
}

.TraitQuestionDetails p {
  /*color: white;*/
  width: 80%;
  text-align: center;
  border: none;
  font-family: 'SawarabiGothic', Helvetica, sans-serif;
  font-size: 1.1rem;
}

.TraitOptionsList {
  display: flex;
  padding-bottom: .5rem;
  flex-direction: column;
  align-items: center;
  gap: .5rem;
  text-align: center;
  border: none;
  font-family: 'SawarabiGothic', Helvetica, sans-serif;
  font-size: 1rem;
}

.TraitOptionDetails {
  width: 90%;
  height: 80px;
  gap: 1rem;
  padding: 1rem;
  border: none;
  border-radius: 10px;
  /*background-color: #FFF0C3;*/
  
  font-size: 16px;
  color: #333;
}

.TraitOptionDetails:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.TraitOptionItem[value="selected"] button {
  background-color: grey
}

@media screen and (max-width: 900px) {
    .Main {
        margin-top: 0.1rem;
        margin-left: 0.1rem;
    }
}
