.container {
    max-width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0 15px 10px 10px;
    overflow-x: hidden;
}

.container h2 {
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 20px;
}


@media screen and (max-width: 768px) {
    .container 
    .search__input {
        width: 100%;
    }
}

.skeleton {
    margin:20px;
    width: 90% !important;
    height: 56px !important;
}

.threads_button {
    margin-top: 20px;
    margin-bottom: 20px;
    z-index: 2;
}

.loader.show {
    visibility: visible;
    opacity: 1;
}

@keyframes spin {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

.square-button {
    /* make the color dark blue */
    background-color: #1b34bf;
}


.search__input {
    width: 95%; /* Default width for desktop */
    background-color: #fcfcff;
}

.conversation_suggestions{
    margin: 20px 0;
}

body {
    background-color: #EFF0FE; /* Replace with your preferred color */
}

.chip {
    margin: 5px 5px 5px 0 !important;
}

.suggestion-text {
    margin-left: 10px;
}

.search-suggestions{
    margin-top: 10px;
    padding-bottom: 10px;
}