
.HomeContainer {
  width: 100%;
  background-color: #F0F1FF;
  /*height: 100%;*/
}

.HomeMain {
  align-items: center;
}

.HomeDemo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.HomeDemo img {
  width: 50%;
  height: auto;
}

.HomeBottom {
  width: 100%;
  padding-bottom: 200px;
}

.LoginButtonClass {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1050px) {
    .HomeTopImage img {
        padding-left: 4rem;
    }
}

@media screen and (max-width: 900px) {
    .HomeTopImage img {
        padding-left: 2rem;
    }
}

@media screen and (max-width: 768px) {
    .Main {
        /*margin-top: 0.1rem;*/
        /*margin-left: 0.1rem;*/
    }
    
    .HomeTopImage img {
        padding-left: 0rem;
    }
    
    .HomeDemo img {
      width: 80%;
      height: auto;
    }

}
