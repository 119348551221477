@import "../../assets/fonts/fonts.css";

.ProfileUpdateContainer {
  width: 100%;
  background-color: #F0F1FF;
  height: 100%;
  font-family: 'SawarabiGothic', Helvetica, sans-serif;
}

.ProfileUpdateItem {    
  display: flex;
  flex-direction: column;
  align-items: center;
}


.ProfileUpdateItem button {
  padding: 10px;
  border-radius: 5px;
  width: 10rem;
  border: none;
  cursor: pointer;
  font-family: 'SawarabiGothic', Helvetica, sans-serif;
  font-size: 1.1rem;
  margin-bottom: 10px;
}

.ProfileUpdateItem button:active {
  background-color: white;
}

.ProfileUpdateItem button:disabled {
  background-color: #BEBEBE;
}

.ProfileUpdateMain {    
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  text-align: flex-start;
  border: none;
  font-family: 'SawarabiGothic', Helvetica, sans-serif;
  font-size: 1.1rem;
}

.ProfileUpdateMain h1 {    
  font-family: 'Sansation', Consolas, sans-serif;
}

.ProfileUpdateMain h2 {    
  font-family: 'Sansation', Consolas, sans-serif;
}

.ProfileUpdateDetails p {
  /*color: white;*/
  width: 30rem;
  text-align: center;
  border: none;
  font-family: 'SawarabiGothic', Helvetica, sans-serif;
  font-size: 1.1rem;
}

.ProfileUpdateDetails {
  margin-bottom: 1px;
}

.ProfileUpdateDetails input {
  width: 70%;
  margin-left: 10%;
  flex-align: center;
  padding: .6rem;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  color: #333;
}
.ProfileUpdateDetails:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.DependentListItem {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 900px) {
    .Main {
        margin-top: 0.1rem;
        margin-left: 0.1rem;
    }
}
