@import "../../assets/fonts/fonts.css";

.CaregiverGPTContainer {
  font-family: 'SawarabiGothic', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 100;

  width: 100%;
  background-color: #F0F1FF;
  height: auto;
}

.CaregiverGPTMain {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  align-items: center;
}

.CaregiverGPTTopbar {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between; /* Center horizontally */
  align-items: center;
  font-family: 'Sansation', Consolas, sans-serif;
}

.CaregiverGPTInputText {
  width: 60%;
  height: 80px;
  gap: 1rem;
  padding: 1rem;
  border: none;
  border-radius: 10px;
  /*background-color: #FFF0C3;*/
  
  font-size: 16px;
  color: #333;
}

.CaregiverGPTResponse {
  width: 60%;
  font-family: 'SawarabiGothic', Arial, Helvetica, sans-serif;
  font-weight: bold;
}