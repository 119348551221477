@import "../assets/fonts/fonts.css";

.AboutContainer {
    font-family: 'SawarabiGothic', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 100;

    width: 100%;
    background-color: #F0F1FF;
    height: 100%;
}

.AboutMain {
  width: 100%;
  margin-left: 15rem;
  align-items: center;
}

.AboutTopImage img {
    width: 40%;
    height: 30%;
    left: 10px;
    top: 20px;
}

.AboutBottom {
  width: 100%;
  padding-bottom: 1000px;
}

@media screen and (max-width: 900px) {
    .AboutMain {
        margin-top: 0.1rem;
        margin-left: 0.1rem;
    }
}