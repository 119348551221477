@import "../assets/fonts/fonts.css";

.DependentProfileContainer {
  width: 100%;
  background-color: #F0F1FF;
  height: 100%;
  font-family: 'SawarabiGothic', Helvetica, sans-serif;
}

.DependentProfileMain {
  display: flex;
  flex-direction: column;
  width: 100%;
  /*margin-left: 15rem;*/
  justify-content: center; /* Center horizontally */
  align-items: center;
  gap: .5rem;
}

.DependentProfileLeftSidebar {
  width: 10rem;
  height: 100%;
  /*margin-left: 15rem;*/
}

.DependentProfileMain button {
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  background-color: #94E5FF80;
  width: 20rem;
  border: none;
  cursor: pointer;
  font-family: 'SawarabiGothic', Helvetica, sans-serif;
  font-size: 1.1rem;
}

.DependentHomeTopbar {
  display: flex;
  flex-direction:row;
  justify-content: center; /* Center horizontally */
  align-items: center;
}

.DependentHomeImage img {
  margin: 2rem;
  width: 20rem;
  height: auto;
}

.NextStepsDependent {
  background-color: #F2E2F3; /* Background color */
  color: #333; /* Text color */
  padding: 10px; /* Padding around the text */
  border: 1px solid #ccc; /* Border for the message box */
  border-radius: 5px; /* Rounded corners for a box-like appearance */
  margin: 10px 0; /* Margin to separate it from other content */
  font-size: 20px; /* Font size for the message text */
}

.DependentProfileDescription {
  width: 70%;
}
.Tooltip {
  display: none;
  position: relative;
  top: -1.5em; /* Adjust the position as needed */
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  position: absolute;
  left: 100%; /* Position to the right of the button */
}

button:hover + .Tooltip {
  display: block;
}

.DependentHomeName {
  margin: 5rem 5rem; 
}

.DependentNavButtons {
  display:flex;
  flex-direction: column;
  gap: 10px;
}

@media screen and (max-width: 900px) {
    .Main {
        margin-top: 0.1rem;
        margin-left: 0.1rem;
    }
    
    .DependentHomeTopbar {
      flex-direction:column;
    }

    .DependentHomeImage img {
      margin:2rem 2rem;
      width: 70%;
      height: auto;
    }

    .DependentHomeName {
      margin: 1rem 1rem; 
    }

}
