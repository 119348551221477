.error-page-box{
    margin-left: 50px;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.error-page-emoji{
    font-family: 'Sansation', Consolas, sans-serif !important;
    font-weight: 250;
}

.emoji{
    font-size: 7rem;
}

.spacer{
    margin-bottom: 20px !important;
}

.button{
    font-family: 'Sansation', Consolas, sans-serif;
    font-weight: 250;
    font-size: 1.5rem;
    border: none;
    margin: 20px !important;
    text-align: center;
    outline: none;
    cursor: pointer;
    height: 30px;
    width: 150px;
}

@media (min-width: 1024px) {
    .error-page-box, .error-page-message {
        margin-left: 300px;
        margin-right: 100px;
    }
}

.error-page-message{
    font-size: 1.5rem;
    text-transform: none;
    height: 100px;
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}