.filter-threads-container{
    margin:10px
}

.thread-header, .message-header {
    display: flex;
    align-items: center;
    font-size: 1.5em;
}

.thread-header{
    margin:10px;
    padding: 10px;
}

.thread-messages{
    padding-bottom: 15px;
    margin-bottom: 120px;
}

.card{
    margin: 10px !important;
    background-color: #E7EFFE !important;
}

.filter-threads-container:focus {
    outline: none;
}

.paper{
    background-color: #EFF0FE !important;
}

.collection-select{
    width:95%;
    margin : 10px;
    background-color: whitesmoke;
}

.thread-header-buttons, .message-header > IOSSwitch {
    margin: 10px;
}

.message-switch{
    margin:10px;
}

.message-text{
    font-size: 1em;
    margin-bottom:10px;

}

.message-container{
    margin:5px
}


.emoji{
    font-size: 2rem !important;
}
.footer {
    display: flex;
    justify-content: space-around; /* Distributes space evenly between the buttons */
    position: fixed;
    bottom: 5px;
    width: 100%;
}

.fixed-button {
    width: 23% !important;
    height: 100px !important;
    margin: 0 !important;
    padding: 10px 0 !important;
    box-sizing: border-box;
    background-color: rgb(255, 254, 254) !important;
}

.loader {
    display: inline-block;
    width: 80px;
    height: 80px;
    border: 6px solid rgba(0, 0, 0, .3);
    border-radius: 50%;
    border-top-color: #3498db;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: spin 1s ease-in-out infinite;
    visibility: visible;
    z-index: 1;
}

.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}