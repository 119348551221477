@import "../../assets/fonts/fonts.css";

.CaregiverProfileContainer {
  width: 100%;
  background-color: #F0F1FF;
  height: 100%;
  font-family: 'SawarabiGothic', Helvetica, sans-serif;
  scroll-behavior: smooth;
}

.CaregiverProfileMain {
  display: flex;
  flex-direction: column;
  width: 100%;
  /*margin-left: 15rem;*/
  justify-content: center; /* Center horizontally */
  align-items: center;
  gap: .5rem;
}

.CaregiverProfileLeftSidebar {
  width: 10rem;
  height: 100%;
  /*margin-left: 15rem;*/
}

.CaregiverProfileMain button {
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  background-color: #94E5FF80;
  width: 20rem;
  border: none;
  cursor: pointer;
  font-family: 'SawarabiGothic', Helvetica, sans-serif;
  font-size: 1.1rem;
}

.CaregiverHomeTopbar {
  display: flex;
  /*justify-content: left;*/
  /*align-items: left;*/
}

.CaregiverHomeImage img {
  /*margin: 2rem;*/
  /*width: 20rem;*/
  /*height: auto;*/
}

.CaregiverHomeName {
    justify-content: center;
    margin: 2rem;
    margin-left: 5rem;
    gap: 1rem;
    width: 10;
}

.CaregiverHomeName p {
  font-size: 18px;
  justify-content: center;
}

.CaregiverHomeName button {
  font-size: 18px;
  /*padding: 1.0rem;*/
  width: 12rem;
  margin-left: 1rem;
  margin-top: 1rem;
  
}

.CaregiverHomeName a {
  text-decoration: none;
}

.CaregiverEmergencyContact {
    background-color: #FAAABD80;
    width: 20rem;
    padding-left: .3rem;
    padding-right: .3rem;
    border-radius: 5px;
}

.CaregiverCategory {
  text-align: left; /* Left-justify the text within the section */
  max-width: 80%; /* Set a maximum width for the section, adjust as needed */
  min-width: 300px; /* Set a minimum width for the section */
  padding: 10px;
  border: 0.5px solid #ddd; /* Optional border for the section */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* Optional box shadow for the section */
  margin: 0 auto; /* Center the section horizontally within the container */
}

@media screen and (max-width: 900px) {
    .Main {
        margin-top: 0.1rem;
        margin-left: 0.1rem;
    }
}
